
/*   ////// */

.InputRow-right{
  position: relative;
}



.imageList{
  position: relative;
}

.input-row-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 12px;
}

.input-label-c {
  font-weight: bold;
  font-size: 1.6rem;
  float: left;
  margin-bottom: 5px;
  margin-left: 40px;
}
.input-container-c {
  width: 230px;
  margin-left: 40px;
}

.input-container-c input {
  box-sizing: border-box;
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1.6rem;
  background-color: #f2f2f2;
  margin-bottom: 20px;
  width: 100%;
}

.input-container-b.valid input {
  border-color: red;
}

.btn-verify {
  margin-top: 27px;
  margin-left: 20px;
}

/* */

.InputRow-left .select_input .input-row-mb {
  margin-bottom: 20px;
}

.input-row-d {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 1.2rem;
}

.title-book-add {
  color: black;
  margin-right: 39rem;
}

.verify {
  color: green;
}





.upload-btn {

  border-radius: 0.5rem;
  background-color: #ed3958;
  border: none;
  margin-left: 0.5rem;
  font-size: 1.2rem;
  /* margin-top: 9px; */
  color: white;

} 

/* Department CSS */
.MainDivForInputField {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.InputRow-left {
  width: 240px;
  /* background: #000; */
  display: flex;
  /* align-items: flex-end; */
  justify-content: left;
  /* margin-right: 20px; */
}

.InputRow-right {
  width: 60%;
  /* background: #000; */
  display: flex;
  align-items: flex-end;
  justify-content: left;
}

.onlymargin {
  padding-left: 20px;
}

.input-container-jj {
  display: flex;
  align-items: flex-end;
  margin-right: 15px;
  margin-bottom: 18px;
}

.dept-btn {
  color: white;
  font-size: 1.3rem;
  padding: 6px 8px;

  border-radius: 5px;
  background: #ed3958;
  color: white;
  border: none;
}

.InputRow-right input,
.InputRow-left input {
  box-sizing: border-box;
  padding: 0.35rem;
  border: 0.2rem solid #ccc;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  margin-bottom: 2rem;
  background-color: #f2f2f2;
}

.InputRow-right1 input,
.InputRow-left1 input {
  border: 0.2rem solid #da1e1e;
}


.input-label-b {
  display: inline-block;
  width: 100%;
  text-align: left;
  font-size: 1.6rem;
}

.choosfile input {
  padding: 0.4rem 0.8rem;
  font-size: 1.2rem;
  width: 210px;
  /* background: #000; */
}

.select_input {
  height: max-content;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.upload-btn {
  border-radius: 5px;
  background-color: #ed3958;
  border: none;
  margin-left: 5px;
  font-size: 1.2rem;
  color: white;
}

.choosfile .upload_file {
  background-color: rgb(58, 95, 58);
}

.choosfile ::file-selector-button {
  font-weight: bold;
  color: dodgerblue;
  background-color: rgb(75, 74, 74);
}



.InputRow-left .input-validate {
  border-color: red
}

.InputRow-right .input-validate {
  border-color: red;
}

.imageList {
  position: relative;
}

.side_img_list {
  position: absolute;
  top: 30px;
  left: 270px;
  background-color: #fffcfc;
  width: 150px;
  height: 100px;
  display: flex;
  border: 1px solid #CCCCCC;
  border-radius: 0.235rem;
  overflow-y: scroll;
}

.side_img_list li {
  list-style: none;
}