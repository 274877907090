.container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.container .filters{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding: 0.8rem;
    /* overflow:auto; */
    flex-wrap: wrap;
}

.filters .select {
    min-width: 18rem;
    font-size: 1.2rem;
}