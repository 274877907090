/* Modal Styles */
.submodal {

    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .submodal-content {
    background-color: #f1efef;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
    max-width: 400px;
    position: relative;
    height: 350px;
  }
  
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
  }




  