.login {
  background-color: rgb(196, 210, 236);
  color: black;
  padding: 40px;
  border: 1px solid black;
}

.form-control {
  width: 300px;
  /* margin-left: 55px; */
}

h1 {
  font-size: 20px;
}

/*  Navigation css */
.first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fbe9e9;
}

.form_div {
  background-color: #585e60;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #888888;
  width: 400px;
  max-width: 100%;
  margin-top: 20px; /* Add some margin to separate the form from the heading */
}

h1 {
  text-align: center; /* Center the heading text */
}

/* 
.first-divss {
  background-color: #09143f;

  height: 600px;
} */
.login-form > h2 {
  font-size: 20px;
}

.login-header {
  width: 100%;
}

/* Login Form */

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
}

.login-form label {
  margin: 16px 0 8px;
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
  color: white;
  align-self: baseline;
  margin-left: 32px;
}
.login-form input {
  width: 300px;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-form button {
  width: 300px;
  height: 40px;
  margin-top: 16px;
  background-color: #d92753;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  color: #000000;
}

/* error messsage */

.error-message {
  color: #e35050;
  /* background-color: rgb(215, 211, 211); */
  margin-top: 10px;
}

.heading-login {
  color: white;
  font-size: 25px;
}
