/* .input_issue{
    box-sizing: border-box;
    padding: 5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f2f2f2;
    margin-bottom: 20px;
    width: 200px;
    float: left;
  }
  
  .input_issue{
    outline: none;
    border-color: #dadce0;
    background-color: #fff;
    box-shadow: 0px 0px 5px #6fa3ff;
  } */

.src_btn {

  padding: 3px;
  border-radius: 5px;
  background-color: #ed3958;
  border-color: white;

  color: white;
}

.input-div {
  display: "flex";
}

/* //////// */

.input-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 300px;
  margin-bottom: 20px;
  margin-top: 12px;
}

.input-labels {
  font-weight: bold;
  font-size: 1.6rem;
  float: left;
  margin-bottom: 5px;
  margin-left: 20px;
}

.input-containers {
  width: 230px;

    height: 55px;
    align-items: flex-end;
    display: flex;
    justify-content: space-around;

}
.input-containers input {
  box-sizing: border-box;
  padding: 3.5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1.6rem;
  background-color: #f2f2f2;
  margin-bottom: 20px;
  width: 100%;
}

.create-new-user {
  color: #239ef5;
  font-size: 1.6rem;
}

.spiner-postion {
  margin-top: 100px;
}

.input-containers.invalid-1 input {
  border-color: red;
}
