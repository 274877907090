.contentContainer {
    width: 100%;
    height: 100%;
}

.contentContainer .inputFields {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.inputFields .inputField{
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.inputField input {
    box-sizing: border-box;
    padding: 0.35rem;
    border: 0.2rem solid #ccc;
    border-radius: 0.5rem;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    background-color: #f2f2f2;
}

.invalid-input input{
  border: 0.2rem solid #da1e1e;
}

.btns {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}