body {
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.h-404 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.p-404 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

a {
  font-size: 1.5rem;
  text-decoration: none;
  color: #007bff;
}

a:hover {
  text-decoration: underline;
}
