.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  font-size: 1.5rem;
}

.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 12px;
}

.input-label {
  font-weight: bold;
  font-size: 1.2rem;
  float: left;
  margin-bottom: 5px;
}

.input-container {
  width: 230px;
}

.input-container input {
  box-sizing: border-box;
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1.6rem;
  background-color: #f2f2f2;
  margin-bottom: 20px;
  width: 100%;
}

/*  */
.input-container.invalid-2 input {
  border-color: red;
}

.submit-btn {
  margin-top: 2px;
  padding: 3px;
  border-radius: 5px;
  background-color: #ed3958;
  border-color: white;
  border: none;
  color: white;
  font-size: 1.5rem;
}

.success-msg {
  color: green;
  font-weight: bold;
  font-size: 2rem;
}
