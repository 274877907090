.card {
  height: 120px; /* increased the height to fit the edit button */
  width: 400px;
  padding: 10px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 5px;
  display: flex; /* added display and position styles for positioning the edit button */
  position: relative;
  background-color:#f2f2f2;
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; /* added width to ensure card-content fills the entire width of the card */
}

.card-row {
  display: flex;
  flex-direction: row;
  align-items: center; /* uncommented align-items to center the row elements vertically */
  margin-bottom: 5px;
}

.card-label {
  font-weight: bold;
  margin-right: 5px;
  font-size: 14px;
}

.card-value {
  font-weight: normal;
  font-size: 12px;
}

.edit-button {
  /* position: absolute;     */
  right: 10px;
  top: 50%;
  /* transform: translateY(-50%); */
  /* padding: 5px 10px; */
  background-color: #c34a6a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  margin-left: 200px;
  margin-top: 5px;
}
