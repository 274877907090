.modal-1 {
    width: 150px;
    height: 150px;
    position: fixed;
    top: 40%;
    left: 60%;
    transform: translate(-50%, -50%);
    background-color: rgba(252, 249, 249, 0.11);
    /* border-radius: 4px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3); */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    animation: fade-in 0.3s ease-in forwards;
  }
  
  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
  
  .success-tick {
    width: 100px;
    height: 100px;
    background-image: url('tick.gif'); /* Replace 'success-tick.png' with your own image path */
    background-size: cover;
    opacity: 0;
    animation: tick-appear 0.3s 0.3s forwards;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes tick-appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  