.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  font-size: 15px;
}

.input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 12px;
}

.input-label {
  font-weight: bold;
  font-size: 1.6rem;
  float: left;
  margin-bottom: 5px;
}

.input-container {
  width: 230px;
}

.input-container input {
  box-sizing: border-box;
  padding: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f2f2f2;
  margin-bottom: 20px;
  width: 100%;
}

.input-container.valids input {
  border-color: red;
}

.submit-btn-rack {
  margin-top: 2px;
  padding: 4px;
  border-radius: 5px;
  border: none;
  background-color: #ed3958;
  /* border-color: white; */
  color: white;
  font-size: 13px;
}

/*    last row */

.input-rowss {
  display: flex;
  flex-direction: row;

  width: 100%;
  margin-bottom: 20px;
  margin-top: 12px;
}

.view-rack {
  margin-left: 20px;
  margin-top: 5px;
  font-weight: 600;
}

.view-rack:hover {
  color: darkred;
}
