.dialogBoxContainer{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    background-color: rgb(0, 0, 0, 0.4);
    top: 0%;
    left: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialogBoxContainer .dialogBox{
    width: 280px;
    height: 140px;
    background-color: white;
    padding: 12px;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    justify-content: space-between;
}

.dialogBox .buttons{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.fileInput{
    display: none;
}

.selectBtn, .uploadBtn{
    width: max-content;
    padding: 6px 12px;
    height: max-content;
    margin-top: 20px;
    font-size: 14px;
}

.uploadBtn{
    border-radius: 5px;
    background: #ed3958;
    color: white;
    border: none;
}

.selectBtn{
    margin-top: 0px;
    border: 1px solid grey;
}

.input-label-b{
    font-weight: 600;
}

.contentContainer{
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
}

.contentContainer .inputFields{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
}

.btn{
    font-size: 14px;
}