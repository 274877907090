.image-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .slider-button {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    font-size: 2rem;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .prev-button {
    left: 0;
  }
  
  .next-button {
    right: 0;
  }
  
  .slider-button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .slider-image {
    max-width: 80%;
    height: auto;
    /* object-fit: cover; */
  }
  