.filterWindow{
  position: absolute;
  width:100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterWindow .dialogBox{
  width: 360px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
}

.dialogBox .filters{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dialogBox .dialogBox_btns{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.dialogBox_btns button{
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
}

.dialogBox_btns button.applyBtn{
  background-color: #c34a6a;
  color: white;
}


.filterSortingBtns{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.filterBtn{
  width: max-content;
  display: flex;
  align-self: flex-end;
  padding: 6px 12px;
  margin: 10px 0px; 
  font-size: 14px;
  background-color: #c34a6a;
  color: white;
  border: none;
  float: right;
  margin-right: 10px;
}

.filterBtn img{
  width: 20px;
  height: 20px;
  object-fit: fill;
  margin-right: 5px;
}

/* styles for the scrollable div */
.rack-list-container {
  overflow-x: auto;
  height: 700px;
  border: 1px solid #bab8b8;
  border-radius: 4px;
  padding: 8px;
  /* width: 400px; */
}

/* styles for the rack table */
.rack-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.4rem;
  line-height: 1.5;
}

/* styles for the table header */
.rack-table th {
  background-color: #f7f7f7;
  border: 1px solid #8e8b8b;
  padding: 8px;
  text-align: left;
}

/* styles for the table body */
.rack-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* styles for each rack row */
.rack-row:hover {
  background-color: #c3b1b1;
}

/* styles for the rack name */
.rack-name {
  font-weight: bold;
}

/* styles for the pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.pagination button {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #555;
  margin-right: 8px;
  padding: 8px 16px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: #f5f5f5;
}

.pagination button:focus {
  outline: none;
}

.btn-edit {
  background-color: #c34a6a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
}

.pagination-btn {

  background-color: #c34a6a;
  color: rgb(44, 43, 43);
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  color: #fffcfc;
}
.pagination-btn-disabled {
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;

  background-color: #cabdc1;
}


.sub-page24 {
  display: flex;
 


}
.sub-page24 div {

width: 50%;
text-align: right;

}

.goBackBtn{
  width: max-content;
  padding: 6px 10px;
  background-color: #c34a6a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.btn-edit2{
  background-color: #555;
}


.search_input{
  width: 80px;
    height: 22px;
    margin: 4px;
    border-radius: 4px;
    border: 1.5px solid rgb(143, 143, 143);
}

.search-find{
  height: 18px;
  width: 30px;
  font-size: 1rem;
  border-radius: 4px;
  border: 1.5px solid rgb(230, 70, 96);
  background-color: #c34a6a;
  color: #fffdfd;



}