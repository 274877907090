.showbookStorebydk{
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }
  /* this is inside top con.. they have three
   parts = heading ,books show , button */

.filterContainer {
    display: flex;
    flex-direction: column;
    height: auto;
}


.mainshowbookStorebydk {
    height: 100%;
    width: 99.50%;
    border: 1.5px solid rgb(146, 146, 146);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3rem;
}

.filterContainer .filters {
    width: 100%;
    display: flex;
    /* justify-content: left; */
    align-items: center;
    gap: 1rem;
    padding: 0.8rem;
    /* overflow:auto; */
    flex-wrap: wrap;
}

.justify-right {
    justify-content: right;
}

.justify-left {
    justify-content: left;
}

.filters .select {
    min-width: 18rem;
    font-size: 1.2rem;
}

.headingformainshowbookStorebydk {
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.booksmainshowbookStorebydk {
    width: 100%;
    overflow: hidden;
}

.btnmainshowbookStorebydk {
    height: 10%;
    width: 100%;
    font-size: 1.6rem;
}

.logoheading {
    width: 15%;
    height: 95%;
}

.logoheading img {
    height: 100%;
    width: 90%;
    object-fit: cover;
}

.headingonly {
    width: 68%;
    text-align: center;
}

.headingonly p {

    font-size: 2.3rem;
    font-weight: 700;
}

.booksmainshowbookStorebydk {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-left: 15px;
}

.bookcart {
    width: 32%;
    height: 95px;
    display: flex;
    align-items: center;
    /* font-family: Poppins; */
    justify-content: flex-start;
    margin: 3px;
    overflow: hidden;
    background-color: #6e6b6b0c;
    flex-direction: column;
}

.bookcart_row_1 {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.bookcart_row_2 {
    /* font-family: 'poppins'; */
    width: 100%;
    height: 35%;
    color: #185bf7;
    padding: 2px;
}

.bk {
    display: flex;
    width: 100%;
    height: 28%;
}



.sk {
    width: 25px;
    height: 21px;
}

.bookcart_row_1 .bookcartimg {
    width: 24%;
    height: 100%;
    border: 1.5px solid rgb(218, 216, 216);
    padding: 1px;
}

.bookcartimg img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.bookcart_row_1 .bookcartcontent {
    width: 47%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 4px 2px;
   }

.bookdetailattribute {
    font-size: 0.7rem;
}

.bookcartcontent .bookdetailattribute {
    height: 16%;
    width: 160%;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.bookcart_row_1 .pageSection {
    height: 100%;
    width: 28%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bookdetailattribute2 {
    font-size: 0.8rem;
    text-align: justify;
    margin: 0px 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}


.bookdetailattribute3 {
    height: 20%;
    font-size: 0.9rem;
    display: flex;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
}

.bold {
    font-weight: bold;
}

.btnmainshowbookStorebydk {
    margin-top: 20px;
    width: 100%;
    display: flex;
}

.btnmainshowbookStorebydk div {
    width: 45%;
    text-align: right;
}

.Page-text {
    font-size: 1.4rem;
}

.bookcartcontent .booknametext {
    width: 100%;
    color: #BE5422;
    height: 30%;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: left;
    padding: 4px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: wrap;
}

/* div#publisher12 {
    margin-right: -129px;
    overflow: visible !important;
} */

.bookcart {
    background: #f2f8ff !important;
    border: 1px solid #DDD;
    padding: 2px;
}


.nk {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    position: relative;
    text-align: center;
}

.nk .scroll_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.book_no {
    font-size: 1rem;
    font-weight: 600;
    position: absolute;
    color: white;
    left: 50%;
    /* z-index: 3; */
    top: 50%;
    transform: translate(-50%, -50%);
}

.pages {
    height: 25px;
}


.booksmainshowbookStorebydk {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 4px 0px;
}


.bookdetailattribute span {
    margin: 0px 4px;
}

.page_number {
    width: 45px;
    padding: 8px;
    border-radius: 4px;
    height: 24px;
    margin-top: 5px;
    font-size: 1.6rem;
}

.page_number_button {
    height: 24px;
    font-size: 1.6rem;

    border-radius: 8px;
    margin-right: 5px;
    margin-top: 5px;
    background-color: #c34a6a;
    color: white;
    border: none;
}