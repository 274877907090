.book-list-container {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
}

.book-list {
  width: 100%;
  border-collapse: collapse;
}

.book-list th,
.book-list td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.book-list th {
  background-color: #f2f2f2;
  position: sticky; /* make the header row sticky */
  top: 0; /* set the top position value */
}

.book-list td button {
  background-color: #d10b57;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.book-list td button:hover {
  background-color: #3e8e41;
}

.book-list td button:active {
  background-color: #36752d;
}



