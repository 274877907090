
.src_btn {
    margin-top: 25px;
    margin-left: 4px;
    padding: 6px;
    border-radius: 5px;
    background-color: #ed3958;
    /* border-color: white; */
    float: left;
    color: white;
    font-size: 12px;
    border: none;
  }
  
  .input-div {
    display: "flex";
  }
  
  /* //////// */
  
  .input-rows {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 300px;
    margin-bottom: 20px;
    margin-top: 12px;
  }
  
  .input-labels {
    font-weight: bold;
    font-size: 1.6rem;
    float: left;
    margin-bottom: 5px;
    margin-left: 20px;
  }
  
  .input-containers {
    width: 230px;
  }



  .valid_input {
  
    border: 2px solid #d81d1d;
   
  
  
  }
  .input-containers input {
    box-sizing: border-box;
    padding: 3.5px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #f2f2f2;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .create-new-user {
    color: #239ef5;
  }
  
  .spiner-postion {
    margin-top: 100px;
  }
  
  
  .select_div {

text-align: left;
font-size: 1.6rem;

  }
.select_div select {

  border: 2px solid #ccc;
  width: 150px;
  background-color: #f2f2f2;
  margin: 10px;
}

  